.tool {
  background-color: $white;
  position: relative;
  overflow: hidden;
  padding-left: 38px;
  margin-bottom: 20px;

  &_wrap {
    float: left;
  }

  &__name {
    color: $black;
    font-size: 30px;
    margin: 25px 0 7px;
    text-transform: uppercase;
    font-weight: 700;
  }

  &__link {
    color: $theme-color;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
  }

  &__img {
    float: right;
    vertical-align: top;
    width: 350px;
    margin-right: 40px;
  }
}
