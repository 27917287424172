// Top navigation
// --------------

.top-nav {
	$height: 100px;

	@include breakpoint(small down) {
		min-height: $height * 0.7;
	}

	//overflow: hidden; //for-tools-menu
	min-height: $height;
	background-color: $white;

	&__logo-container {
		$v-pad: 13px;
		$h-pad: 16px;

		@include breakpoint(small down) {
			$v-pad: $v-pad * 0.7;
			$h-pad: $h-pad * 0.7;

			position: absolute;
			top: 0;
			left: 0;
			z-index: 25;
			padding: $v-pad $h-pad;
			height: $height * 0.7;
		}

		display: inline-block;
		float: left;
		margin: 0;
		padding: $v-pad $h-pad;
		height: $height;
		background-color: $theme-color;
		box-shadow: 0 6px 16px rgba(24, 59, 90, 0.11)
	}
	&__logo {
		@include breakpoint(small down) {
			max-width: 0.57 * $height * 0.7;
			max-height: 0.57 * $height * 0.7;
		}

		display: block;
		max-width: 0.57 * $height;
		max-height: 0.57 * $height;

		&:not(:first-child) {
			margin-top: 0.04 * $height;
		}
		&:first-child {
			animation: rotation 30s linear infinite;
		}
	}
	&__expand {
		display: none;
	}
	&__links {
		text-align: left;

		&-item {
			display: inline-block;
			text-align: center;

			&_tools {
				position: relative;

				&:hover .top-nav__link_tools.is-active {
					&:before,
					&:after {
						content: none;
					}
				}

				.menu-level-1,
				.menu-level-ins,
				.menu-level-last {
					display: none;
					z-index: 30;
				}

				@include breakpoint(medium up) {

					&:hover {
						.top-nav__link {
							color: $theme-color;
						}
						.menu-level {
							background-color: $white;
							box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
							position: absolute;
							height: 970px; // Default value 610px. TODO: Make auto height
						}
						.menu-level-ins {
							top: 0;
							left: 100%;
						}
						.menu-level-last {
							width: 414px;
							top: 0;
							left: 100%;
							background-color: #f7f7f7;
							@include breakpoint(885 down) {
								width: 350px;
							}
							@include breakpoint(820 down) {
								width: 320px;
							}
						}
						.menu-level-1 {
							display: block;
							top: 100%;
							left: 0;

							>.menu-item {
								&:first-of-type {
									&:before {
										content: '';
										width: 17px;
										height: 11px;
										background-image: url(../img/png/top-triangle-tr.png);
										position: absolute;
										top: -11px;
										left: calc(50% - 7.5px);
									}
									&:hover:before {
										background-image: url(../img/png/top-triangle.png);
									}
								}
							}

							.menu-item {
								&__separator {
									&:hover {
										background-color: transparent;
										.menu-item__text {
											color: $menu-text;
											border-bottom: 1px solid #d7d7d7;
											
										}
									}
									.menu-item__text {
										cursor: default;
										line-height: 2;
										&:after {
											content: none;
										}
									}
								}
								&:hover {
									&> .menu-level {
										display: block;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&__link {
		$trs-time: 0.2s;

		@include trs($trs-time);

		position: relative;
		z-index: 3;
		display: inline-block;
		margin: 0 35px;
		color: $black;
		line-height: $height;

		&:before,
		&:after {
			position: absolute;
			right: 50%;
			bottom: 0;
			left: 50%;
			display: block;
			height: 3px;
			background-color: $theme-color;
			content: '';
			transition: all 0.2s ease-in-out;
		}

		&:hover,
		&.is-active {
			color: $theme-color;

			&:before {
				left: -5px;
			}
			&:after {
				right: -5px;
			}
		}

		&_tools {
			margin-left: 50px;
			&:hover {
				&:before,
				&:after {
					content: none;
				}
			}
			@include breakpoint(medium down) {
				margin-left: 35px;
			}
		}

		&.is-active {
			color: $theme-color;
		}
		@include breakpoint(1020 down) {
			// &_tools {
			// 	padding: 0;
			// }
		}
		@include breakpoint(xmedium down) {
			margin: 0 18px;
		}
		@include breakpoint(825px down) {
			margin: 0 13px;
		}
		@include breakpoint (765 down){
			&:hover {
				color: $black;
				&:after,
				&:before {
					content: none;
				}
			}
		}
	}
	&__instruments {
		display: inline-block;
		float: right;
		vertical-align: middle;
		line-height: $height;
	}

	// Mobile view
	// -----------
	&__block {
		@include breakpoint(small down) {
			$height: $height * 0.7;

			@include trs(0.3s);

			position: absolute;
			width: 0;
			top: 0;
			right: -100%;
			z-index: 3;
			overflow: hidden;;
			background-color: $white;


			&.is-active {
				position: absolute;
				display: block;
				right: 0;
				width: 100vw;
			}
			.top-nav {
				&__links {
					// position: absolute;
					margin-top: $height;
					// left: 0;
					width: 100%;

					&-item {
						display: block;
						border-top: 1px solid #d7d7d7;
						font-size: 18px;
						background-color: $theme-color;

						&_tools {
							position: relative;
						}

						&:last-child {
							border-bottom: 1px solid #d7d7d7;
						}
					}
				}
				&__link {
					margin: 0;
					width: 100%;
					line-height: $height;
					color: $white;
					padding-left: 20px;
					text-align: left;
				}
				&__instruments {
					position: absolute;
					top: 0;
					right: 22%;
					line-height: $height;
					transform: scale(1.2);
				}
				&__expand {
					display: block;
					line-height: $height;
					width: $height/1.25;
					position: absolute;
					top: 0;
					right: 0;
					border-left: 1px solid #d7d7d7;
					color: $white;
					font-size: 25px;
					z-index: 5;
				}
			}

			.menu-item {
				border-top: 1px solid #d7d7d7;
				background: $white;
				position: relative;

				.top-nav__expand {
					color: $menu-text;

					&.is-active {
						color: $white;
					}
				}

				&__text {
					margin: 0;
					padding: 0 0 0 20px;
					line-height: $height;
					border: none;

					&:after {
						content: none;
					}
				}
			}
		}
	}
}

// End of mobile view
// ------------------

.menu-item {
	width: 100%;
	transition: all 0.2s ease-in-out;

	&__text {
		color: $menu-text;
		line-height: 63px;
		display: block;
		text-align: left;
		margin-left: 39px;
		margin-right: 22px;
		padding-right: 31px;
		border-bottom: 1px solid #d7d7d7;
		position: relative;
		white-space: nowrap;

		@include breakpoint(1020 down) {
			margin-left: 30px;
			margin-right: 25px;
			padding-right: 25px;
		}

		@include breakpoint(985 down) {
			margin-left: 16px;
	    margin-right: 15px;
	    padding-right: 15px;
		}
		@include breakpoint(825 down) {
			margin-left: 10px;
	    margin-right: 10px;
	    padding-right: 15px;
		}

		&:after {
			content: '';
			width: 5px;
			height: 9px;
			position: absolute;
			background-image: url(../img/png/right-triangle.png);
			right: 0;
			top: calc(50% - 4.5px);
		}

	}

	&:hover {
		background-color: $theme-color;
		>.menu-item__text {
			color: $white;
			border: 0;
			&:after {
				background-image: url(../img/png/right-triangle-white.png);
			}
		}
	}
}

.card-image {
	background-color: #e2edf6;
	&__image {
		// margin: 57px 63px 44px;
		// max-width: 288px;
		// TODO: Uncomment styles above if pictures will be normal size
	}
	@include breakpoint(950 down) {
		&__image {
			max-width: 282px;
		}
	}
	@include breakpoint(885 down) {
		&__image {
			margin: 57px 34px 44px;
		}
	}
	@include breakpoint(820 down) {
		&__image {
			margin: 57px 19px 44px;
		}
	}
}
.card-content {
	padding: 25px 10px;
	text-align: center;
	@include breakpoint(885 down) {
		padding: 25px 10px;
	}
	&__title {
		font-size: 22px;
		font-weight: 700;
		color: #797979;
		margin-bottom: 15px;
	}
	&__info {
		display: inline-block;
		vertical-align: top;
		width: 32%;
		padding: 0 5px;
		&__value {
			font-size: 18px;
			font-weight: 900;
		}
		&__dimension {
			font-size: 17px;
			font-weight: 700;
		}
		&__characteristic {
			text-align: center;
			font-size: 12px;
			font-weight: 400;
		}
	}
	.btn {
		margin-top: 28px;
	}
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
