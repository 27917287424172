// Contact block
// -------------

.contact-block {
	padding: 20px 35px;
	background-color: $white;
	font-family: ProximaNova-700;
	line-height: 1.5;

	&__title {
		font-family: ProximaNova-700;
		text-transform: uppercase;
		margin-bottom: 15px;
	}
	&__subblock{
		&:not(:first-of-type) {
			margin-top: 20px;
		}
	}
	&__subtitle {
		font-family: $body-font-family;
		color: $dark-gray;
		margin: 0;
	}
	&__subtext {
		margin: 0;
		line-height: 1.5;

		a {
			color: $black;
			@include trs();

			&:visited {
				color: $black;
			}
			&:hover,
			&:active {
				color: $theme-color;
			}
		}
	}
	&__hr {
		min-height: 189px;
	}
}
