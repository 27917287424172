// Feedback form
// -------------

.fbk-form {
	text-align: center;

	&__field {
		position: relative;
		display: block;

		&-input {
			display: block;
			padding: 25px 20px 10px;
			margin-bottom: 20px;
			width: 100%;
			background-color: $light-gray;
			border: none;
			box-shadow: none;
			@include trs();
			font-family: ProximaNova-700;

			&:focus,
			&.is-active {
				background-color: $light-gray;
				border: none;
				outline: none;

				& + .fbk-form__field-label {
					top: 5px;
					font-size: 12px;
				}
			}
			&:focus {
				box-shadow: 0 2px 8px rgba($black, 0.2) !important;
				@include trs();

			}

			&.is-invalid-input {
				box-shadow: 0 0 0, inset 0 0 10px map-get($foundation-palette, alert);
				@include trs();

				& ~ .fbk-form__field-error {
					display: block;
				}
			}
		}

		&-label {
			display: block;
			position: absolute;
			top: 16px;
			left: 20px;
			color: $medium-gray;
			@include trs();

			&.is-active {
				top: 5px;
				font-size: 12px;
			}
		}

		&-error {
			position: absolute;
			top: 100%;
			left: 0;
			font-size: 12px;
			color: map-get($foundation-palette, alert);
		}
	}

	&__textarea {
		min-height: 380px;
		resize: none;

		&.is-invalid-input {
			box-shadow: 0 0 0, inset 0 0 10px map-get($foundation-palette, alert);
		}

		@include breakpoint(small only) {
			min-height: 250px;
		}
	}

	&__submit {
		@util hide-visually;

		&-btn {
			position: absolute;
			right: 35px;
			bottom: 35px;

			@include breakpoint(small only) {
				position: relative 0 0;
				width: 100%;
				margin-top: 10px;
			}
		}
	}
}
