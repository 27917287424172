main {
	max-width: $global-width;
	margin: 0 auto;
}
body {
  -ms-overflow-x: hidden;
}

.compare-btn-main {
	padding: 13px 0 0 17px;
	position: fixed;
	bottom: 20px;
	right: 5%;
	width: 50px;
	height: 50px;
	background: white;
	box-shadow: 0 6px 16px rgba(57, 101, 168, 0.25);
	cursor: pointer;
	display: none;
	z-index: 1;

	&__close {
		position: absolute;
		right: -7px;
		top: -7px;
		cursor: pointer;
	}
}

.site-error {
	height: calc(100vh - 236px);
	max-width: 65rem;
	margin-left: auto;
	margin-right: auto;
	@include breakpoint(950 down) {
		height: calc(100vh - 255px);
	}
	@include breakpoint(766 down) {
		height: calc(100vh - 206px);
	}
}