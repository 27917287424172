.inst-descr-compare {
    .inst-specs {
        &__name {
            @media (max-width: 750px) {
                font-size: 15px;
            }
        }
    }
    .print {
        color: $theme-color;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        position: relative;
        padding-left: 35px;
        // width: 38%;
        display: inline-block;
        &:before {
            content: url('/statics/img/png/print-icon.png');
            position: absolute;
            left: 0;
        }
    }
    .to-print {
        display: none;
    }
    .inst-specs-table {
        &__name {
            margin-right: 0;
            width: 38%;
        }
        &__value {
            width: 20%;
        }
        &-row>div {
            line-height: 1.1;
            padding: 15px 0;
        }
    }
    .measure-system {
        float: none;
    }
    // compare engines table
    .table-responsive {
        min-height: .01%;
        overflow-x: auto;
    }
    .compare-engines-table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 10px 0;
        td,
        th {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            padding: 12px 0;
            @media (max-width: 750px) {
                font-size: 15px;
            }
            a {
                position: relative;
                .del-text {
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 31px;
                    text-transform: uppercase;
                    opacity: 0;
                    transition: opacity ease .25s;
                    @media (max-width: 750px) {
                        display: none;
                    }
                }
                &:hover {
                    .del-text {
                        opacity: 1;
                    }
                }
            }
            .inst-desc-title {
                display: inline-block;
                color: #797979;
                font-size: 18px;
                font-weight: 700;
                @media (max-width: 750px) {
                    font-size: 15px;
                }
            }
        }
        td {
            border-bottom: 1px solid #e7e7e7;
            &.no-border {
                border-bottom: 0;
            }
        }
        &__name {
            width: 38%;
            text-align: left;
        }
        &__value {
            width: 20%;
        }
        .select-engine {
            @media (max-width: 750px) {
                width: 120px;
            }
        }
    }
}

.select-engine {
    display: inline-block;
    width: 223px;
    height: 50px;
    box-shadow: 0 6px 16px rgba(57, 101, 168, 0.25);
    border: 1px solid #00adef;
    user-select: none;
    position: relative;
    z-index: 2;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    
    &__list-main {
        width: 100%;
        display: none;
        position: absolute;
        top: 48px;
        max-height: 40vh;
        overflow-y: auto;
        margin-left: 0;
    }
    &__label,
    ul li {
        width: 100%;
        display: block;
        background-color: #f8f8f8;
        cursor: pointer;
        &.selected {
            opacity: .5;
            cursor: not-allowed;
            color: #00adef;
        }
        &.separator {
            cursor: not-allowed;
        }
    }
    &__label {
        position: relative;
        height: 100%;
        padding: 12px 20px;
        border-bottom: 1px solid rgb(236, 236, 236);
        @media (max-width: 750px) {
            padding: 12px;
        }
        &:hover {
            background-color: white;
        }
        &.first {
            border-bottom: 0;
            &:after {
                content: url('/statics/img/png/down-triangle.png');
                position: absolute;
                right: 15px;
                top: 10px;
            }
        }
    }
    &__label.show~&__list {
        display: block;
    }
    &__list {
        position: relative;
        display: none;
        z-index: 1;
        margin: 0;
        li {
            padding: 15px 15px 15px 40px;
            @media (max-width: 750px) {
                padding: 15px 15px 15px 20px;
            }
            &:hover {
                background-color: white;
            }
        }
    }
}

@media print {
    .to-print {
        display: block !important;
    }
    .top-nav,
    .inst-main__link,
    .print,
    .select-engine,
    .inst-specs,
    .inst-desc-title,
    .compare-btn-main,
    .js_remove_engine,
    .footer {
        display: none !important;
    }
    body {
        background: #FFF;
        color: #000;
        font-size: 14px;
    }
    .table-responsive {
        overflow: visible !important;
    }
    .compare-engines-table {
        width: 100%;
    }
    .compare-engines-table {
        &__name,
        &__value {
            width: auto !important;
        }
    }
}