// Font-face
// ---------
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff ttf otf svg) {
	$src: null;
	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);
	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}
	@font-face {
		font-weight: $weight;
		font-style: $style;
		font-family: quote($name);
		src: $src;
	}
}

// Transition
// ----------
@mixin trs($time: 0.15s, $prop: all, $delay: 0s, $easing: ease-in-out) {
	transition: $prop $time $delay $easing;
}

// Aligns
// ------
