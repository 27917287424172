// Stage block
// -----------

.stage-block {
	text-align: center;

	&__title {
		position: relative;
		font-family: ProximaNova-700;
		font-size: 16px;
		line-height: 1;
		padding-bottom: 27px;

		&:after {
			content: '';
			@extend %ham;
			bottom: 0;
			@util triangle(10px,  #{$theme-color}, down);
			border-top-width: 15px !important;
		}
	}

	&__content {
		position: relative;
		min-height: 254px;
		max-width: 776px;
		margin: 0 auto;
		margin-top: 25px;
		background-color: $theme-color;
		color: $white;
		line-height: 1.5;
		lost-utility: clearfix;
	}
	&__text,
	&__img {
		lost-column: 1/2 2 0px;
	}
	&__text {
		padding: 40px 30px;
		text-align: left;
	}
	&__img {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		background-image: url(../img/jpg/stage-block-1.jpg);
		background-size: cover;
		background-position: center;
	}

	@include breakpoint(small only) {
		&__text,
		&__img {
			lost-column: none;
			position: static;
		}
		&__text {
			padding: 25px 20px;
		}
		&__img {
			height: calc(50vw);
		}
	}
}
