// Footer
// ------

.footer {
	$footer-height: 136px;

	@include breakpoint(small down) {
		text-align: center;
	}

	min-height: $footer-height;
	width: 100%;
	background-color: $deep-black;
	color: $white;

	&__brand {
		@include breakpoint(small down) {
			padding-top: 15px;
		}

		padding-top: 28px;

		&-copyright {
			@include breakpoint(small down) {
				margin-top: 10px;
			}

			display: block;
			margin-top: 16px;
			min-width: 220px;
			color: $dark-gray;
			font-size: 12px
		}
	}
	&__developer {
		@include breakpoint(small down) {
			float: none;
			line-height: $footer-height / 2;
		}

		position: relative;
		display: inline-block;
		float: right;
		margin-left: 42px;
		min-width: 208px;
		color: $white;
		font-size: 14px;
		line-height: $footer-height;

		&:before {
			@extend %vam;
			@include trs();

			right: 100%;
			display: block;
			padding-right: 9px;
			color: #ededed;
			content: '\e902';
			font: 28px Icomoon;
		}
		&:hover {
			color: $white;

			&:before {
				color: #1c84c5;
			}
		}
		&--color-emphasize {
			color: $medium-gray;
		}
	}
	&-nav {
		@include breakpoint(small down) {
			display: none;
		}

		&__links {
			@include breakpoint(950 down) {
				margin-top: $footer-height / 10;
			}

			text-align: center;

			&-item {
				@include breakpoint(950 down) {
					display: block;
					line-height: $footer-height / 5;
				}

				display: inline-block;
				line-height: $footer-height
			}
		}
		&__link {
			$trs-time: 0.2s;

			@include trs($trs-time);

			position: relative;
			z-index: 1;
			display: inline-block;
			padding: 0 12px;
			color: $white;
			font-size: 14px;
			line-height: 1.2;

			&:after {
				@include trs($trs-time);

				display: block;
				visibility: hidden;
				width: 100%;
				height: 1px;
				background-color: transparent;
				content: '';
				transform: translateY(10px);

			}
			&:hover {
				color: $white;

				&:after {
					visibility: visible;
					background-color: transparentize($white, 0.3);
					transform: translateY(2px);
				}
			}
		}
	}
}
