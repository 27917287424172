// Information block
// -----------------

.infoblock {
	&__content {
		clear: fix;
	}
	&__content-text {
		lost-column: 6/10 2 40px;

		@include breakpoint(small only) {
			lost-column: none;
		}
	}
	&__content-img {
		lost-column: 4/10 2 40px;

		@include breakpoint(small only) {
			display: none;
		}
	}

	&__title,
	&__subtitle {
		font-family: ProximaNova-700;
		margin-bottom: 30px;
	}
	&__title {
		text-transform: uppercase;
		&_outside {
			padding-left: 25px;
		}
	}
	&__description {
		padding-right: 20px;
		line-height: 1.5;
		margin-bottom: 0;

		&-list-item {
			list-style-type: disc;
			list-style-position: inside;
		}

		&-img {
			width: 100%;
			box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
			margin: 35px 0;
		}
	}

	&__links {
		margin: 20px 0;

		&-link {
			@extend h5;
			text-transform: uppercase;
			font-family: ProximaNova-700;
			display: block;
			color: $theme-color;
			margin-bottom: 0;
			line-height: 2.2;
			@include breakpoint(small only) {
				font-size: 16px;
				line-height: 2;
			}

			@include trs();

			&:hover {
				color: darken($theme-color, 20);
			}
		}
	}

	&__aside-img {
		margin: 0;
		width: 100%;
		box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);

		&:not(:first-child) {
			margin-top: 40px;
		}
	}

	&--title {
		margin-bottom: 10px !important;

		.infoblock__content-text {
			padding-bottom: 30px;
		}
	}
}

.resizable-infoblock {

	position: relative;
	background-color: $white;
	padding-top: 50px;
	padding-bottom: 60px;

	&__resize-wrap {
		@include trs(1s);
		max-height: 4000px;
		overflow: hidden;
	}

	&:not(.is-active) .resizable-infoblock__resize-wrap {
		max-height: 0;
	}
}
