.ab_main,
.ab_header,
.ab_header__slider {
	position: relative;
}
.ab_header {
	&__slider {

	}
}

.ab_about {
	$offset-left: 25px;

	margin-top: 90px;
	padding-left: $offset-left;
	padding-right: 50px;

	&__subblock {
		margin-bottom: 30px;
	}

	&__resize-btn {
		position: absolute;
		left: $offset-left;
		bottom: 40px;
	}
}

// Header slider
// -------------

.header-slider {
	.img,
	.video {
		@extend %cam;
		width: 100%;
		height: 100%;
		object-fit: cover;
		max-width: 9999px;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: auto;
		}
	}

	.video {
		&:-ms-fullscreen {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			transform: translate(0%, 0%);
		}
	}

	.js_slider {
		position: relative;
		margin: 0 auto;
		text-align: center;
		background-color: #000;
		&__slide {
			position: relative;
			height: 500px;
			// cursor: pointer;

			@include breakpoint(small only) {
				height: 300px;
			}
		}
	}

	.js_slider-nav {
		@extend %ham;
		width: 100%;
		text-align: center;
		transform: translate(-50%, calc(-120% - 10px));
		-ms-transform: translateY(-120%) translateY(-10px) translateX(-50%);
		max-width: $global-width;

		@include breakpoint(small only) {
			display: none;
		}

		.slick-track {
			width: 100% !important;
		}

		&__item {
			position: relative;
			display: inline-block !important;
			float: none !important;
			max-width: 150px;
			max-height: 100px;
			height: 6vw;
			cursor: pointer;
			overflow: hidden;
			box-shadow: 0 7px 12px rgba(0, 0, 0, 0.3);


			&:not(:last-of-type) {
				margin-right: 20px;
			}

			&:after {
				content: '';
				display: block;
				@extend %cam;
				width: 100%;
				height: 100%;
				background-color: transparent;
				box-shadow: inset 0 0 0 3px $theme-color;
				@include trs();
			}
			&:not(.slick-current) {
				box-shadow: none;

				&:after {
					background-color: rgba(black, 0.4);
					box-shadow: none;
				}
			}
		}

		&__item-ico {
			@extend %cam;
			z-index: 3;
		}
	}
}
@include breakpoint(500 down) {
	.header-slider {
		.img,
		.video {
			height: 100%;
		}
	}
}
//Slider video contorls
//------------
.controls {
	&,& > * {
		padding:0;
		margin:0;
	}
	overflow:hidden;
	background:transparent;
	width: 100%;
	// height: 8.0971659919028340080971659919028%; /* of figure's height */
	position: relative;

	&[data-state=hidden] {
		display: none;
	}
	&[data-state=visible] {
		display: block;
	}

	& > * {
		// float: left;
		width: 3.90625%;
		height: 100%;
		margin-left: 0.1953125%;
		display: block;
	}
	& > *:first-child {
		margin-left: 0;
	}
	& .progress {
		cursor: pointer;
		width: 50%;
	}
	& button {
		text-align:center;
		overflow:hidden;
		white-space:nowrap;
		text-overflow:ellipsis;
		border: none;
		cursor: pointer;
		text-indent: -99999px;
		background: transparent;
		background-size: contain;
		background-repeat: no-repeat;

		&:hover,
		&:focus {
			opacity: .5;
		}

		&[data-state="play"] {
			width: 32px;
			height: 32px;
			background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDQ0My4zMDcgNDQzLjMwNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDQzLjMwNyA0NDMuMzA2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTQxNS45MzQsMjEyLjc5OUwzNi43ODgsMi4wOTdDMzIuNDExLTAuMzc3LDI4LjY1LTAuNjYxLDI1LjUxLDEuMjQyYy0zLjE0LDEuOTAyLTQuNzA4LDUuMzI4LTQuNzA4LDEwLjI3NlY0MzEuNzggICBjMCw0Ljk1MiwxLjU2OSw4LjM4MSw0LjcwOCwxMC4yODRjMy4xNCwxLjkwMiw2LjkwMSwxLjYyMiwxMS4yNzgtMC44NTVsMzc5LjE0Ni0yMTAuNzAzYzQuMzgxLTIuNDc4LDYuNTcxLTUuNDM0LDYuNTcxLTguODU2ICAgQzQyMi41MDUsMjE4LjIyNCw0MjAuMzE0LDIxNS4yNzQsNDE1LjkzNCwyMTIuNzk5eiIgZmlsbD0iI0ZGRkZGRiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=)
		}
		&[data-state="pause"] {
			width: 32px;
			height: 32px;
			background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzNjUgMzY1IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzNjUgMzY1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCI+CjxnPgoJPHJlY3QgeD0iNzQuNSIgd2lkdGg9IjczIiBoZWlnaHQ9IjM2NSIgZmlsbD0iI0ZGRkZGRiIvPgoJPHJlY3QgeD0iMjE3LjUiIHdpZHRoPSI3MyIgaGVpZ2h0PSIzNjUiIGZpbGw9IiNGRkZGRkYiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K)
		}
	}

	& progress {
		display:block;
		width:100%;
		height:81%;
		margin-top:0.125rem;
		border:none;
		color:#0095dd;
		-moz-border-radius:2px;
		-webkit-border-radius:2px;
		border-radius:2px;

		&[data-state="fake"] {
			background:#e6e6e6;
			height:65%;
		}
		& span {
			width:0%;
			height:100%;
			display:inline-block;
			background-color:#2a84cd;
		}
		&::-moz-progress-bar {
			background-color:#0095dd;
		}
		&::-webkit-progress-value {
			background-color:#0095dd;
		}
	}
}


.ab_team {
	$padding: 40px;
	$img-size: 110px;
	$img-margin-right: 15px;

	margin-top: 80px;
	margin-bottom: 90px;

	@media (max-width: 400px) {
		p {
			font-size: 15px;
		}
	}
	
	&__persons {
		clear: fix;
	}

	&__person {
		position: relative;
		padding: $padding;
		@include trs(0.3s);
		@media (max-width: 400px) {
			padding: 40px 15px;
		}

		&.is-active {
			z-index: 10;
			box-shadow: 0 6px 46px rgba(0, 0, 0, 0.15);

			.ab_team__person-title-text {
				transform: translateY(-50%);
			}
		}

		&:not(.is-active) {
			&:hover {
				z-index: 5;
				color: $white;
				box-shadow: 0 6px 46px rgba(0, 0, 0, 0.15);
				background-color: $theme-color;

				.ab_team__resize-btn {
					background-color: $white;

					&:after,
					&:before {
						background-color: $theme-color;
					}
				}
			}
		}
	}
	&__person-title {
		position: relative;
		margin: 0;

		&-img-wrap {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			size: $img-size;
			border-radius: 50%;
			overflow: hidden;
			z-index: 1;
			margin-right: $img-margin-right;
		}
		&-img {
			@extend %cam;
			width: auto;
			height: 100%;
			max-width: unset;
		}

		&-text {
			position: relative;
			transform: translateY(-80%);
//			display: inline-block;
			display: block;
			position: absolute;
			top: 50%;
			left: $img-size + $img-margin-right;
//			vertical-align: middle;
			@include trs(0.3s, $prop: transform);

			@media (max-width: 400px) {
				transform: translateY(-65%);
				left: 125px;
			}
			&-name {
				margin-bottom: 0;

				@include breakpoint(medium down) {
					font-size: 20px;
				}

				@media (max-width: 400px) {
					font-size: 18px;
				}
			}

			&-position {
				margin-bottom: 0;

				@include breakpoint(medium only) {
					font-size: 14px;
				}
			}
		}
	}
	&__person-description {
		margin-bottom: 40px;
		padding-top: 20px;
		p {
			margin-bottom: 0;
		}
		&-para {
			margin-top: 25px;
			margin-bottom: 0;

			&:last-of-type {
				margin-bottom: 60px;
			}
		}
	}

	&__resize-btn {
		position: absolute;
		left: $padding + $img-size + $img-margin-right;
		bottom: $padding;
		@include trs(0.3s);

		&:hover {
			transform: scale(1.2) perspective(1px) translate3d(0,0,0);
			perspective: 1px;
		}

		&.is-active {
			left: $padding;
		}
	}

	&__grid-block {
		lost-column: 1/2 2 0;

		@include breakpoint(small only) {
			lost-column: none;
		}
	}
}
// **********************
.js_slider-nav {
    transition: all ease .5s;
}
.video:hover+.controls,
.controls:hover {
    opacity: .8;
}
.controls {
    display: block !important;
    position: absolute;
    top: 15px;
    left: 0;
    opacity: 0;
    transition: opacity ease .3s;
}
.controls .progress {
    display: inline-block;
    vertical-align: top;
    height: 33px;
    margin-right: 10px;
}
.controls progress {
    display: block;
    width: 100%;
    height: 81%;
    margin-top: 2px;
    margin-top: 0.125rem;
    border: none;
    overflow: hidden;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #0095dd;
    /* Internet Explorer uses this value as the progress bar's value colour */
}
.controls progress span {
    width: 0%;
    height: 100%;
    display: inline-block;
    background-color: #2a84cd;
}
.controls button {
    text-indent: 0;
    display: inline-block;
    margin-right: 10px;
    border: 0;
    width: 32px;
    &:active,
    &:focus {
        outline: none;
    }
}
.controls .fa {
    color: #0095dd;
    font-size: 32px;
	font-family: FontAwesome;
}

.video-dimensions {
	width: 100% !important;
	height: 100% !important;
}
.video-js .vjs-tech {
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
// .video-js .vjs-big-play-button {
// 	display: none !important;
// }
.js_person__resize-wrap {
	display: none;
}
.ab_team__resize-btn {
	@media (max-width: 400px) {
		left: 140px;
		bottom: 25px;
	}
}