%vam {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
%ham {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}
%cam {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
