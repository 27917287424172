.inst-main {
  margin: 30px auto 40px;
  min-height: calc(100vh - 306px);

  &__link {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    color: $theme-color;
    @include breakpoint(1070 down) {
      margin-left: 20px;
    }
  }
}
.inst-wrap {
  margin-top: 30px;
  padding: 55px 40px 65px;
  background-color: #fefefe;
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
.slider-wrap,
.inst-specs-download {
  display: inline-block;
  vertical-align: top;
}
.slider-wrap {
  margin-right: 5%;
  max-width: 39%;
}
.inst-slider {
  padding-bottom: 20px;
  .slick-slide {
    img {
      margin: 0 auto;
    }
  }
  & img {
    // height: 253px;
    max-height: 253px;
  }
  @include breakpoint(765 down) {
    & img {
      height: 200px;
    }
  }
  @include breakpoint(600 down) {
    & img {
      // height: 253px;
      max-height: 253px;
    }
  }
  @include breakpoint(500 down) {
    & img {
      height: 200px;
    }
  }
  @include breakpoint(400 down) {
    & img {
      height: 150px;
    }
  }
}
/*.slider-nav {
  text-align: center;
  overflow-y: auto;
  height: 100px;

  & div {
    display: inline-block;
    width: 120px;
    height: 90px;
    overflow: hidden;
    & img {
      // height: 90px;
      min-height: 90px;
    }
  }
}*/
.slick {
  &-prev, &-next {
    color: $theme-color;
    font-size: 25px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &-prev {
    left: -25px;
  }
  &-next {
    right: -25px;
  }
}
.slider-nav {
  .slick-slide img {
    max-height: 90px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    // object-position: 25% 50%;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      // max-height: 90px;
      height: auto;
    }
  }
  &__item {
    height: 90px;
    display: inline-block;
  }
}
.inst-descr {
  float: right;
  width: 56%;
}
.inst-name {
  color: $dark-gray;
  font-size: 22px;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 39px;
}
.inst-compare {
  text-transform: uppercase;
  font-size: 14px;
  float: right;
  line-height: 31px;
  color: $theme-color;
  position: relative;
  margin-right: 27px;
  font-weight: 700;

  &:after {
    content: '';
    position: absolute;
    top: 7px;
    right: -27px;
    background: url('/statics/img/png/compare-img.png');
    width: 17px;
    height: 16px;
  }
}
.inst-specs {
  &__name {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    color: $black;
    display: inline-block;
    margin-top: 10px;
  }
}
.measure-system {
  float: right;

  &__item {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: $medium-gray;
    cursor: pointer;
    line-height: 16px;

    &:hover,
    &_active {
      color: $theme-color;
    }
  }
}
.inst-specs-table {

  .inst-specs-table-more {
    .inst-specs-table-row {
      transition: all 1s 0s ease-in-out;
      max-height: 2000px;
    }
  }

  .inst-specs-table-more:not(.is-active)>.inst-specs-table-row {
    max-height: 0;
    overflow: hidden;
  }

  &-row>div {
    border-top: 1px solid #e7e7e7;
    // line-height: 50px;
    font-size: 18px;
    color: $black;
  }

  &__name {
    width: 50%;
    display: inline-block;
    margin-right: 5%;
    vertical-align: top;
    padding: 20px 0;
  }

  &__value {
    line-height: 1.5;
    width: 44%;
    display: inline-block;
    padding: 20px 0;
    &_imperical {
      display: none;
    }
  }
}
.inst-specs__btn {
  margin-top: 20px;
  padding: 15px 45px;
  float: right;
  font-size: 14px;
}
.inst-specs-download {
  margin-top: 35px;
  min-width: 200px;

  &__file-img {
    float: left;
    margin-right: 14px;
  }

  &__link {
    display: inline-block;
    color: $black;
    font-size: 18px;
    line-height: 18px;
    border-bottom: 1px solid $medium-gray;
    font-weight: 700;

    &:first-letter {
      text-transform: uppercase;
    }

    &:hover {
      border-bottom-color: $theme-color;
    }
  }

  &__file-info {
    text-transform: uppercase;
    font-size: 14px;
    color: $medium-gray;
    margin-right: 15px;
  }

  &__print {
    text-transform: uppercase;
    font-size: 14px;
    color: $theme-color;
    font-weight: 700;
    target-new: tab;
  }
}

@include breakpoint(965 down) {
  .inst-descr {
    float: none;
    width: 100%;
    margin-bottom: 100px;
  }
  .slider-wrap {
    max-width: 45%;
  }
}

@include breakpoint(600 down) {
  .slider-wrap {
    margin: 0 auto;
    max-width: 74%;
    display: block;
  }
  .inst-specs-table__name {
    width: 54%;
  }
  .inst-specs-table__value {
    width: 39%;
  }
  .inst-specs-table-row > div {
    font-size: 15px;
    line-height: 20px;
    padding: 15px 0;
  }
}
@include breakpoint(765 down) {
  .inst-specs__btn {
    display: block;
    float: none;
    margin: 20px auto;
  }
}
@include breakpoint(500 down) {
  .inst-name {
    margin-bottom: 0;
    display: block;
  }
  .inst-compare {
    float: none;
    display: inline-block;
    margin-bottom: 10px;
  }
  .inst-specs__name {
    display: block;
    width: 100%;
  }
  .measure-system {
    float: none;
    text-align: center;
  }
}

@include breakpoint(350 down) {
  .inst-specs-table-row > div {
    padding: 10px 0;
  }
}
