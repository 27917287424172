.sr_main {
	margin: 80px auto 90px;
	padding: 55px 40px 65px;
	background-color: $white;

	@include breakpoint(small only) {
		margin: 0;
		padding: 30px 20px 35px;
	}
}

.st_services {
	p {
		line-height: 1.6 !important; 
	}
}