// Front page header
// -----------------

.fp_header {
	min-height: 460px;
	background: linear-gradient(-30deg, rgba($white, 0) 0%, rgba($white, 0.1) 100%), url(../img/jpg/fp_header-bg.jpg) center no-repeat;
	background-size: cover;
	color: $white;
	position: relative;

	&__content {
		margin-top: 103px;
		margin-bottom: 20px;
		padding: 55px 39px !important;
		min-height: 357px;
		background-color: transparentize($theme-color, 0.1);
	}
	@include breakpoint(765 down) {
		&__content {
			width: 75%;
		}
	}
	@include breakpoint(555 down) {
		&__title {
			font-size: 24px;
		}
	}
	@include breakpoint(465 down) {
		&__content {
			width: 100%;
		}
		&__title {
			font-size: 26px;
		}
	}
	@include breakpoint(375 down) {
		&__title {
			font-size: 21px;
		}
	}
	&__title {
		@include breakpoint(medium only) {
			font-size: 36 / 1040 * 100vw;
		}

		margin: 0;
		margin-top: 40px;
		text-transform: uppercase;
		word-wrap: break-word;
		font-family: ProximaNova-900;
		line-height: 1
	}
	&__description {
		margin-top: 35px;
		max-width: 390px;
		font-size: 18px;
		line-height: 1.56;
	}
	&__content-button {
		margin-top: 21px;
	}
	&::after {
		content: '';
		position: absolute;
		top: 100%;
		width: 100%;
		height: 21px;
		background: url(../img/png/fp_header_bottom_gradient.png) 0 0 repeat-x;
		z-index: 1;
	}
}


// Front page services
// -------------------

.fp_services {
	$tab-labels-height: 76px;

	position: relative;
	overflow: hidden;
	background-color: $white;

	&__content {
		padding: 20px 17px;
	}
	&__text-content {
		@include breakpoint(small down) {
			width: 100%;
		}

		display: inline-block;
		padding: 33px 20px;
		width: 45%;

		&-parag {
			margin-bottom: 20px;
		}
	}
	&__tabs-panel-img {
		@include breakpoint(small down) {
			display: none;
		}
		
		float: right;
		max-width: 55%;
		max-height: 314px;
		margin-top: 31px;
	}
	&__tabs-panel-title {
		margin: 0;
		line-height: 1.4;
		font-size: 24px;
		font-family: ProximaNova-700;
    	text-transform: uppercase;
		text-align: center;
	}
	&__text-content-ref {
		margin-bottom: 27px;
	}
	&__btn {
		min-width: 240px;
		text-align: center;
	}
}

.brand-ref {
	&__logo {
		display: inline-block;
		max-width: 22%;
		vertical-align: middle;
	}
	&__description {
		display: inline-block;
		padding-left: 3%;
		max-width: 75%;
		color: $dark-gray;
		vertical-align: middle;
		font-size: 12px;
		line-height: 1.5;
	}
}


// Frontn page instruments
// -----------------------

.fp_instruments {
	clear: fix;
	margin-top: 45px;
	margin-bottom: 60px;
	text-align: center;

	&__title {
		text-transform: uppercase;
		font-family: ProximaNova-700;
	}
	&__blocks {
		margin: 27px 0;
	}
	&__button-more {
		margin-top: 40px;
	}
}

.instruments-block {
	$vertical-padding: 40px;
	$horizontal-padding: 43px;

	@include breakpoint(small down) {
		text-align: center;

		&__button {
			min-width: 208px;
		}
	}

	position: relative;
	overflow: hidden;
	padding: $vertical-padding $horizontal-padding !important;
	min-height: 227px;
	text-align: left;

	&__bg {
		position: absolute 0 0;
		z-index: -1;
		margin: 0;

		img {
			@extend %cam;

			min-width: 100%;
			min-height: 100%;
		}
		&:after {
			position: absolute 0 0;
			content: '';
		}
	}
	&__title {
		@extend %cam;
		@include breakpoint(medium only) {
			font-size: 30 / 1040 * 100vw;
		}

		top: 82px;
		color: $white;
		text-align: center;
		text-transform: uppercase;
		font-family: ProximaNova-700
	}
	&__button {
		@extend %ham;

		position: absolute;
		bottom: $vertical-padding;
	}
	&:nth-child(odd) {
		.instruments-block__bg:after {
			background-color: transparentize($theme-color, 0.2);
		}
	}
	&:nth-child(even) {
		.instruments-block__bg:after {
			background-color: transparentize($theme-color, 0.54);
		}
	}
}
