@custom-selector :--hover :hover, :active;
$btn-shadow-color: rgba(57, 101, 168, 0.25);
$btn-main-color: $theme-color;
$btn-alt-color: $white;

.btn {
	@include trs(0.1s);

	position: relative;
	z-index: 1;
	display: inline-block;
	border: 1px solid $btn-main-color;
	box-shadow: 0 6px 16px $btn-shadow-color;
	text-transform: uppercase;
	font-family: ProximaNova-700;

	&:active {
		@include trs(0.05s);

		box-shadow: 0 0px 0px $btn-shadow-color;
	}
	&:before,
	&:after {
		@extend %ham;
		@include trs(0.3s);

		top: 0;
		z-index: -1;
		display: block;
		height: 100%;
		content: '';
	}
	&:before {
		width: 100%;
	}
	&:after {
		width: 80%;
	}

	//---------------
	&--main {
		border-color: $btn-main-color;
		color: $btn-alt-color !important;

		&:before {
			background-color: $btn-main-color;
		}
		&:--hover:after {
			width: 100%;
			background-color: darken($btn-main-color, 10);
		}
	}

	&--alt {
		border-color: $btn-alt-color;
		color: $black !important;

		&:before {
			background-color: $btn-alt-color;
		}

		&:--hover {
			border-color: rgba($btn-main-color, 0.5);

			&:after {
				width: 100%;
				background-color: rgba($btn-main-color, 0.5);
			}
		}
	}

	&--blank {
		border-color: $btn-alt-color;
		background-color: transparent;
		color: $btn-alt-color !important;

		&:--hover {
			color: $btn-main-color !important;

			&:after {
				width: 100%;
				background-color: $btn-alt-color;
			}
		}
	}

	&--tab {
		border-color: transparent;
		color: $black !important;
		box-shadow: none;

		&:hover {
			border-color: $btn-alt-color;
			box-shadow: 0 6px 16px $btn-shadow-color;

		}

		.is-active & {
			background-color: $btn-alt-color;
			border-color: $btn-alt-color;
			box-shadow: 0 6px 16px $btn-shadow-color;
		}
	}

	//---------------
	&--big {
		padding: 14px 39px;
		font-size: 14px;
	}
	&--medium {
		padding: 10px 40px;
		font-size: 12px;
	}
}

.resize-btn {
	$btn-size: 30px;
	$sign-weight: 2px;
	$sign-length: 50%;

	position: relative;
	size: $btn-size;
	background-color: $theme-color;
	border-radius: 50%;
	box-shadow: 0 3px 6px rgba(57, 101, 168, 0.25);
	@include trs();

	&:after,
	&:before {
		content: '';
		display: block;
		@extend %cam;
		background-color: $white;
		@include trs();
	}
	&:after {
		size: $sign-weight $sign-length;
	}
	&:before {
		size: $sign-length $sign-weight;
	}

	&:hover {
		background-color: darken($theme-color, 20);
	}

	&.is-active {
		&:after {
			opacity: 0;
		}
		&:before {
			width: $sign-length * 0.8;
		}
	}
}

.play-ico {
	$ico-size: 44px !default;

	position: relative;
	size: $ico-size;
	border: 3px solid $theme-color;
	border-radius: 50%;

	&:after {
		@extend %cam;
		transform: translate(-40%, -50%);
		content: '';
		display: block;
		border: 0px solid transparent;
		border-left: $ico-size * 17/44 solid $theme-color;
		border-top-width: $ico-size * 10/44;
		border-bottom-width: $ico-size * 10/44;
	}
}
