$gutter: 20px;

.ct_main {
	lost-utility: clearfix;
	margin-top: 35px;
	margin-bottom: 40px;
	min-height: calc(100vh - 311px);
}

.ct_contacts-wrap {
	position: relative;
}

.ct_contacts {
	float: left;
	width: calc(50% - #{$gutter / 2});

	&__block {
		&:not(:first-child) {
			margin-top: $gutter;
		}
		a {
			color: $black;
		}
	}
}

.ct_feedback {
	float: right;
	width: calc(50% - #{$gutter / 2});
	height: 100%;

	background-color: $white;
	padding: 20px 35px;
	height: 100%;

	&__title {
		font-family: ProximaNova-700;
		text-transform: uppercase;
		margin-bottom: 15px;
	}
	.fbk-form__submit-btn {
		position: relative;
		right: -140px;
		bottom: 0;
	}
}

.fbk-form__field.field-contactform-message .fbk-form__field-label {
	background: #f8f8f8;
	width: 100%;
	left: 0;
	margin-top: -5px;
	padding-top: 10px;
	text-align: left;
	padding-left:20px;
}

@include breakpoint(small only) {
	.ct_contacts,
	.ct_feedback {
		position: static;
		display: block;
		width: 100%;
	}

	.ct_feedback {
		margin-top: $gutter;
	}
}
