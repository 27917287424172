.title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
}

.sub-title {
    font-size: 19px;
    font-weight: 700;
    line-height: 24px;
    margin: 50px 0;
}

.hide {
    display: none !important;
}

.selected-engines-wrap {
    position: relative;
    display: none;
    .btn_float-right {
        @media (max-width: 750px) {
            margin-top: 20px;
            float: none;
        }
    }
    .btn {
        cursor: pointer;
        &_normal {
            margin-left: 20px;
            padding: 15px 45px;
            font-size: 0.875rem;
        }
        &_float-right {
            float: right;
            @media (max-width: 750px) {
                float: none;
            }
        }
    }
}

.selected-engines-btn-close {
    position: absolute;
    top: -13px;
    right: -13px;
    cursor: pointer;
}

.selected-engines {
    display: inline-block;
    max-width: 80%;
    @media (max-width: 750px) {
        display: block;
        width: 100%;
    }
}

.selected-items {
    list-style: none;
}

.selected-item {
    display: inline-block;
    margin-right: 40px;
    &:last-of-type {
        margin-right: 0;
    }
    &__number {
        color: $theme-color;
        font-size: 30px;
        font-weight: 300;
    }
    &__text {
        font-size: 14px;
        font-weight: 700;
        &.text_normal {
            font-weight: 400;
        }
    }
    &__btn-delete {
        cursor: pointer;
        margin-left: 17px;
        img {
            vertical-align: baseline;
        }
    }
    @media (max-width: 750px) {
        display: block;
    }
}

.engines-column {
    // width: 16%;
    width: 150px;
    margin-right: 5%;
    display: inline-block;
    vertical-align: top;
    &:last-of-type {
        margin-right: 0;
    }
    &__title {
        line-height: 30px;
        font-size: 19px;
        font-weight: 700;
    }
    &-item {
        a {
            // color: $black;
        }
        &__text {
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            text-transform: uppercase;
            display: inline-block;
            max-width: 103px;
        }
        &__icon {
            float: right;
            cursor: pointer;
            opacity: .5;
            transition: all .3s;
            margin-right: 30px;
            margin-top: 6px;
            position: relative;
            width: 17px;
            height: 16px;
            background: url('/statics/img/png/compare-img.png') 50% 50% no-repeat;
            &.selected {
                background: url('/statics/img/png/compare-img-selected.png') 50% 50% no-repeat;
            }
            &:hover {
                opacity: 1;
                &:before {
                    content: attr(data-tooltip);
                    position: absolute;
                    left: 27px;
                    top: -16px;
                    background-color: white;
                    padding: 10px 30px;
                    white-space: nowrap;
                    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
                    text-align: center;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 31px;
                    color: $black;
                    z-index: 99;
                }
                &:after {
                    width: 0;
                    height: 0;
                    border: 6px solid transparent;
                    content: '';
                    border-right-color: white;
                    border-left: none;
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    margin-top: -6px;
                    margin-left: 4px;
                    z-index: 99;
                }
            }
        }
    }
}

.engines-info {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    p {
        margin: 45px 0;
    }
    img {
        max-width: 100%;
        height: auto;
        margin: 0 auto;
        &.shadow {
            box-shadow: 0 0 20px rgba(0, 0, 0, .1);
        }
        &.mtop-130 {
            margin-top: 130px;
        }
        &.standart {
            display: block;
            margin: 50px auto;
        }
        @media (max-width: 750px) {
            display: block;
            margin: 50px auto !important;
        }
    }
    li {
        position: relative;
        padding-left: 40px;
        &:before {
            content: url('/statics/img/png/list-rectangle.png');
            position: absolute;
            left: 15px;
        }
    }
    .left-col {
        display: inline-block;
        width: 60%;
        &.v-top {
            vertical-align: top;
        }
        &.v-bottom {
            vertical-align: bottom;
        }
        &.for-mwd {
            @media (max-width: 750px) {
                display: inline-block;
                width: 60%;
            }
        }
        p {
            margin-bottom: 0;
        }
        @media (max-width: 750px) {
            display: block;
            width: 100%;
        }
    }
    .right-col {
        display: inline-block;
        width: 39%;
        text-align: center;
        &.v-top {
            vertical-align: top;
        }
        &.v-bottom {
            vertical-align: bottom;
        }
        &.for-mwd {
            @media (max-width: 750px) {
                display: inline-block;
                width: auto;
                img {
                    display: inline-block;
                }
            }
        }
        @media (max-width: 750px) {
            display: block;
            width: 100%;
        }
    }
    .list-title {
        margin: 40px 0 10px 0;
    }
    .prop-list {
        &.x-large {
            li {
                margin-bottom: 70px;
            }
        }
        &.large {
            li {
                margin-bottom: 20px;
                &:last-of-type {
                    margin-bottom: 0;
                    @media (max-width: 750px) {
                        margin-bottom: 50px;
                    }
                }
            }
        }
        &.small {
            li {
                margin-bottom: 10px;
            }
        }
    }
}