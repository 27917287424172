.st_tabs {
	margin-top: 40px;
	text-align: center;

	&__title {
		display: inline-block;
		margin: 0 10px;
	}
}

.st_tabs-content {
	$offset-left: 40px;
	$offset-bottom: 60px;

	margin-top: 40px;

	&__panel {
		visibility: hidden;
		height: 0;
		overflow: hidden;
		opacity: 0;

		@include trs(0.5s, $prop: opacity);

		&.is-active {
			visibility: visible;
			height: auto;
			opacity: 1;
		}
	}

	&__block {

		padding-left: $offset-left;
		margin-bottom: 30px;

		@include breakpoint(small only) {
			padding-left: $offset-left / 2;
			padding-right: $offset-left / 2;
		}
	}
	&__subblock {
		margin-bottom: 80px;
	}

	$offset-left: 40px;
	$offset-bottom: 60px;

	&__resize-btn {
		position: absolute;
		bottom: $offset-bottom;
		left: $offset-left;

		@include breakpoint(small only) {
			left: $offset-left / 2;
		}
	}
}


