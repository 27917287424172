.sw_main {
	margin: 35px auto 40px;
	padding: 55px 40px 65px;
	background-color: $white;

	@include breakpoint(small only) {
		margin: 0;
		padding: 30px 20px 35px;
	}
}

.sw_description {
	&__title {
		@extend h2;

		font-family: ProximaNova-700;
		text-transform: uppercase;
	}

	&__text {
		line-height: 1.5;
		max-width: 830px;
		//Согласно требованиям заказчика: убрать первый заголовок ЭТАПЫ ТО, т.к. он повторяется
		//padding-top: 30px;

		p, p>* {
			color: #121212;
			font-family: ProximaNova, Open Sans, Gill Sans MT, Gill Sans, Corbel, Arial, sans-serif!important;
			font-size: 16px!important;
			font-weight: normal!important;
			background-color: transparent!important;
			line-height: 1.5!important;
		}

		@include breakpoint(small only) {
			text-align: justify;
			padding-top: 20px;
		}
	}
}

.sw_stages {
	margin-top: 70px;
	text-align: center;

	@include breakpoint(small only) {
		margin-top: 50px;
	}

	&__title {
		@extend h3;

		font-family: ProximaNova-700;
		text-transform: uppercase;
	}

	&__blocks {
		margin-top: 45px;
	}

	&__block {
		position: relative;
		top: -30px;
		opacity: 0;
		@include trs(0.5s, $delay: 0.2s);

		&:not(:first-child) {
			margin-top: 60px;

			@include breakpoint(small only) {
				margin-top: 30px;
			}
		}

		&.is-active {
			top: 0;
			opacity: 1;
		}
	}
}
