.nav-instruments {
	&__search {
		display: inline-block;
	}
	&__lang-toggler {
		float: right;
	}
	&__search {
		display: inline-block;
	}
}


// Search input
// ------------

.search-input {
	[type="checkbox"][id="search-toggle"] {
		@util hide-visually;
	}
	&__label {
		position: relative;
		top: 1px;
		display: inline-block;
		margin-right: 10px;
		cursor: pointer;
		size: 16px;
	}
	&__label-toggle {
		cursor: pointer;

		&:hover {
			&:after {
				color: $theme-color;
			}
		}
		&:after {
			@extend %cam;
			@include trs();

			display: inline-block;
			color: $dark-gray;
			content: '\f002';
			font-size: 16px;
			font-family: FontAwesome;
			line-height: 1;
			size: 16px;
		}
	}
	&__search-form {
		@extend %vam;
		@include trs(0.3s);
		@include breakpoint(small down) {
			box-shadow: none;
		}

		right: 25px;
		z-index: 1;
		overflow: hidden;
		width: 0;
		box-shadow: -5px 0 20px $theme-color;
		line-height: 1;

		input {
			padding: 3px 5px;
			width: 100%;
			border: 2px solid $theme-color;
			font-size: 14px;

			&:focus {
				outline: none;
			}
		}
	}
	[type="checkbox"][id="search-toggle"]:checked + .search-input__label {
		.search-input__label-toggle:after {
			color: $theme-color;
		}
		.search-input__search-form {
			@include breakpoint(small down) {
				width: calc(80vw - 85px);
			}

			width: 170px
		}
	}
}

// Language toggler
// ----------------

.lang-toggler {
	$color: $dark-gray;
	$lang-toggle-height: 20px;
	$translate-time: 0.2s;
	$translate-delay: $translate-time;

	position: relative;
	z-index: 1;
	padding-right: 15px;
	color: $color;
	text-transform: uppercase;
	font-size: 11px;

	[type="checkbox"][id="lang-toggle"] {
		@util hide-visually;
	}
	&__toggle {
		cursor: pointer;

		&:after {
			@extend %vam;
			@include trs($translate-time * 1.5);

			left: 100%;
			display: block;
			border: 1px solid $color;
			border-top-color: transparent;
			border-left-color: transparent;
			content: '';
			transform: translate(-15px, -100%) rotate(45deg) rotateY(0);
			size: 7px;
		}
		&-label {
			display: inline-block;
			padding: 0 7px;
			background-color: $white;
			line-height: $lang-toggle-height;
			transition: color 0.15s ease-in-out,
				background-color 0.15s ease-in-out;
		}
	}
	[type="checkbox"][id="lang-toggle"]:checked + .lang-toggler__toggle {
		&:after {
			transform: translate(-10px, -50%) rotate(45deg) rotateZ(90deg);
		}
		.lang-toggler__toggle-label {
			transition: color 0.15s ease-in-out,
				background-color 0.15s ease-in-out,
				transform $translate-time ease-in-out;
			transform: translateY(-50%);

			&:hover {
				background-color: $theme-color;
				color: $white;
			}
		}
	}
	[type="checkbox"][id="lang-toggle"]:not(:checked) + .lang-toggler__toggle {
		.lang-toggler__toggle-label {
			transition: color 0.15s ease-in-out,
				background-color 0.15s ease-in-out,
				transform $translate-time $translate-delay ease-in-out;
		}
		&:hover {
			.lang-toggler__toggle-label {
				color: $theme-color;
			}
			&:after {
				border-right-color: $theme-color;
				border-bottom-color: $theme-color;
			}
		}
	}
	[type="checkbox"][id="lang-toggle"]:checked ~ .lang-toggler__langs {
		transition: color 0.15s ease-in-out,
			background-color 0.15s ease-in-out,
			transform $translate-time $translate-delay ease-in-out;
		transform: translateY(0);
	}
	[type="checkbox"][id="lang-toggle"]:not(:checked) ~ .lang-toggler__langs {
		transition: color 0.15s ease-in-out,
			background-color 0.15s ease-in-out,
			transform $translate-time ease-in-out;
	}
	&__langs {
		@extend %vam;

		left: 0;
		z-index: -1;
		padding: 0 7px;
		color: $color;
		line-height: $lang-toggle-height;

		&:hover {
			background-color: $theme-color;
			color: $white;
		}
	}
}
